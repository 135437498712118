import React from "react"
/** @jsx jsx */
import { jsx, Flex, Link } from "theme-ui"
import { FiInstagram } from "react-icons/fi"
import { FiTwitter } from "react-icons/fi"
import { FiGithub } from "react-icons/fi"

const socialIcon = {
  width: "30px",
  height: "30px",
}

const SocialNav = props => {
  return (
    <Flex sx={{ justifyContent: "center", flexDirection: props.flexDirection }}>
      <Link
        href="https://www.instagram.com/dreds15/?hl=en"
        target="_blank"
        mx={2}
      >
        <FiInstagram sx={socialIcon} />
      </Link>
      <Link href="https://twitter.com/dareds15" target="_blank" mx={2}>
        <FiTwitter sx={socialIcon} />
      </Link>
      <Link href="https://github.com/lempira" target="_blank" mx={2}>
        <FiGithub sx={socialIcon} />
      </Link>
    </Flex>
  )
}
SocialNav.defaultProps = {
  flexDirection: "row",
}
export default SocialNav
