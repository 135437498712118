import React, { useState } from "react"
/** @jsx jsx */
import { jsx, Container, Box, Flex, NavLink } from "theme-ui"
import { AiOutlineMenu } from "react-icons/ai"
import { AiOutlineClose } from "react-icons/ai"
import { GoEyeClosed } from "react-icons/go"
import { BiBookOpen } from "react-icons/bi"
import { motion } from "framer-motion"
// CUSTOM COMPONENTS
import { useAppContext } from "../../context/AppContext"
import TableOfContentsSmall from "../TableOfContents/TableOfContentsSmall"
import Logo from "../Logo"
import MobileMenu from "../MobileMenu"
import ThemeChange from "../ThemeChange"
const navBarLarge = {
  backgroundColor: "#ffffff00",
  position: "fixed",
  margin: 3,
  zIndex: 1000,
}
const navBarSmall = {
  backgroundColor: "background",
  position: "fixed",
  zIndex: 1000,
  width: "100%",
}
const smallNavItem = {
  alignSelf: "center",
  padding: 2,
}
// const smallHeaderWrapper = {
//   display: "flex",
//   justifyContent: "space-between",
// }
const mobileMenu = viewport => ({
  height: "100vh",
  width: "100%",
  // border: "5px solid tomato",
  position: "fixed",
  top: -1 * viewport.height,
  zIndex: 999,
})
const icon = {
  width: "32px",
  height: "32px",
  color: "text",
}
// Animation Variants
const menuVariants = viewport => ({
  hidden: { top: -1 * viewport.height - 200 },
  visible: { top: 0 },
})

const Header = ({ data }) => {
  const { size, showNav } = useAppContext()
  // console.log(`rendered header`)
  // console.log({ size })
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showToc, setShowToc] = useState(false)

  // CLICK HANDLERS
  const onMenuClick = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const renderLargeScreen = () => {
    return (
      <>
        <motion.div
          sx={{ ...navBarLarge, ...{ left: 0 } }}
          animate={{ x: showNav ? 0 : -400 }}
          initial={{ x: 0 }}
          transition={{ x: { type: "spring", stiffness: 50, duration: 0.7 } }}
        >
          <Box>
            <Flex as="nav">
              <NavLink href="/">
                <Box sx={{ width: "100px", backgroundColor: "background" }}>
                  <Logo />
                </Box>
              </NavLink>
              <NavLink href="/About">About</NavLink>
            </Flex>
          </Box>
        </motion.div>
        <motion.div
          sx={{ ...navBarLarge, ...{ right: 0 } }}
          animate={{ x: showNav ? 0 : 400 }}
          initial={{ x: 0 }}
          transition={{ x: { type: "spring", stiffness: 50, duration: 0.7 } }}
        >
          <Flex as="nav">
            <NavLink>
              <ThemeChange />
            </NavLink>
          </Flex>
        </motion.div>
      </>
    )
  }
  const renderSmallScreen = () => {
    return (
      // <Container id="header" p={0}>
      <Box id="header" p={0}>
        {size.viewport.height ? (
          <>
            <motion.div
              id="motionMenu"
              sx={mobileMenu(size.viewport)}
              initial={"hidden"}
              animate={showMobileMenu ? "visible" : "hidden"}
              variants={menuVariants(size.viewport)}
            >
              <MobileMenu />
            </motion.div>
            {data.renderToc ? (
              <TableOfContentsSmall
                showToc={showToc}
                setShowToc={setShowToc}
                data={data.tableOfContents.items}
              />
            ) : null}
          </>
        ) : null}

        <motion.div
          sx={navBarSmall}
          animate={{ opacity: showNav ? 1 : 0 }}
          initial={{ opacity: 1 }}
          transition={{
            opacity: { type: "spring", stiffness: 50, duration: 0.7 },
          }}
        >
          <Container>
            <Flex as="nav" sx={{ justifyContent: "space-between" }}>
              <NavLink href="/" sx={smallNavItem}>
                <Box sx={{ width: "45px", backgroundColor: "background" }}>
                  <Logo />
                </Box>
              </NavLink>
              {data.renderToc ? (
                <Box
                  sx={smallNavItem}
                  onClick={() => {
                    setShowToc(!showToc)
                  }}
                >
                  {showToc ? (
                    <GoEyeClosed sx={icon} />
                  ) : (
                    <BiBookOpen sx={icon} />
                  )}
                </Box>
              ) : null}

              <Box
                // mr={4}
                sx={smallNavItem}
                onClick={() => onMenuClick()}
              >
                {showMobileMenu ? (
                  <AiOutlineClose sx={icon} />
                ) : (
                  <AiOutlineMenu sx={icon} />
                )}
              </Box>
            </Flex>
          </Container>
        </motion.div>
      </Box>
    )
  }

  return (
    <>
      {size.headerSize
        ? size.headerSize === "large"
          ? renderLargeScreen()
          : renderSmallScreen()
        : null}
    </>
  )
}

Header.defaultProps = { data: {} }
export default Header
