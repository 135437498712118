import React from "react"
import Header from "./Header"
/** @jsx jsx */
import { jsx, ThemeProvider, useThemeUI, Box } from "theme-ui"
import Social from "../components/Social"
// import { BlogPostContextProvider } from "../components/BlogPostContext"
import { AppContextProvider } from "../context/AppContext"

const socialNav = {
  position: "fixed",
  left: 0,
  top: "50%",
  zIndex: 10000,
  display: ["none", "none", "block"],
}
const Layout = ({ data, children }) => {
  const context = useThemeUI()
  const { theme } = context
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <Box sx={socialNav} ml={3}>
          <Social flexDirection="column" />
        </Box>
        <Header data={data} />
        <Box sx={{ paddingTop: 60 }}>{children}</Box>
      </AppContextProvider>
    </ThemeProvider>
  )
}

Layout.defaultProps = { data: {} }
export default Layout
