import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { useAppContext } from "../../context/AppContext"
import scrollTo from "gatsby-plugin-smoothscroll"

// Animation Variants
const menuVariants = viewport => ({
  hidden: { top: -1 * viewport.height - 50 },
  visible: { top: 0 },
})

const tocWrapper = {
  height: "100%",
  width: "100%",
  backgroundColor: "background",
}
const tocItem = {
  cursor: "pointer",
  "&:hover": {
    border: "1px dashed",
    borderColor: "muted",
  },
}

const tocItemsModes = {
  visible: {
    opacity: 1,
    x: 0,
  },
  hidden: {
    opacity: 0,
    x: -20,
  },
}
const mobileTocMenu = viewport => ({
  height: "100vh",
  width: "100%",
  position: "fixed",
  top: -1 * viewport.height,
  zIndex: 998,
})
const TableOfContentsSmall = ({ data, showToc, setShowToc }) => {
  const { size } = useAppContext()
  const controls = useAnimation()

  useEffect(() => {
    controls.start(showToc ? "visible" : "hidden")
  }, [showToc, controls])

  const scrollClick = anchor => {
    setShowToc(false)
    scrollTo(anchor)
  }

  return (
    <>
      <motion.div
        id="mobileToc"
        sx={mobileTocMenu(size.viewport)}
        initial={"hidden"}
        animate={showToc ? "visible" : "hidden"}
        variants={menuVariants(size.viewport)}
      >
        <Box sx={tocWrapper}>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              paddingLeft: 36,
            }}
          >
            <Box sx={{ alignSelf: "center" }}>
              {data.map((h1, i) => {
                const items = h1.items
                return (
                  <div key={i}>
                    <motion.div
                      animate={controls}
                      variants={tocItemsModes}
                      transition={{ duration: 0.6, delay: 0.3 }}
                    >
                      <Box
                        sx={{ ...{ fontSize: 3 }, ...tocItem }}
                        key={i}
                        ml={1}
                        p={2}
                        onClick={() => scrollClick(h1.url)}
                      >
                        {h1.title}
                      </Box>
                    </motion.div>
                    {items
                      ? items.map((h2, j) => {
                          return (
                            <motion.div
                              key={j}
                              animate={controls}
                              variants={tocItemsModes}
                              transition={{ duration: 0.6, delay: 0.6 }}
                            >
                              <Box
                                sx={tocItem}
                                ml={3}
                                p={1}
                                onClick={() => scrollClick(h2.url)}
                              >
                                {h2.title}
                              </Box>
                            </motion.div>
                          )
                        })
                      : null}
                  </div>
                )
              })}
            </Box>
          </Flex>
        </Box>
      </motion.div>
    </>
  )
}
export default TableOfContentsSmall
