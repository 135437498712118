import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
const Logo = () => {
  return (
    <svg
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210.57 247.69"
    >
      <path
        d="M.46.13V247.57H210.78V.13ZM192.22,229H19V18.68h173.2Z"
        transform="translate(-0.34)"
        sx={{
          fill: "text",
          stroke: "#000",
          strokeMiterlimit: 10,
          strokeWidth: "0.25px",
        }}
      />
      <path
        d="M40.6,47c3.91,0,7,1.45,8.61,3.7V39.38h10.4V72H49.21V68.51c-1.54,2.2-4.49,3.74-8.66,3.74-7,0-12.62-4.79-12.62-12.67S33.52,47,40.6,47Zm3.27,7.52c-2.95,0-5.38,1.77-5.38,5.11s2.43,5.15,5.38,5.15,5.39-1.81,5.39-5.15S46.78,54.47,43.87,54.47Z"
        transform="translate(-0.34)"
        sx={{ fill: "primary" }}
      />
      <path
        d="M75.87,72H65.52V47.26H75.87v4.35A12.4,12.4,0,0,1,85.43,47v9.2H82.52c-4.27,0-6.65,1.14-6.65,4.92Z"
        transform="translate(-0.34)"
        sx={{ fill: "primary" }}
      />
      <path
        d="M102.48,72.25c-8.76,0-15-4.79-15-12.67S93.61,47,102.48,47s14.94,4.71,14.94,12.37a12.5,12.5,0,0,1-.16,2H97.68c.21,2.86,2,4,4.38,4a4,4,0,0,0,3.69-1.93h11C115.42,68.47,109.87,72.25,102.48,72.25ZM97.73,57.16h9.13c0-2.2-2.06-3.39-4.43-3.39S98.2,54.92,97.73,57.16Z"
        transform="translate(-0.34)"
        sx={{ fill: "primary" }}
      />
      <path
        d="M132.63,47c3.91,0,7,1.45,8.6,3.7V39.38h10.41V72H141.23V68.51c-1.53,2.2-4.48,3.74-8.65,3.74-7,0-12.62-4.79-12.62-12.67S125.55,47,132.63,47Zm3.27,7.52c-3,0-5.38,1.77-5.38,5.11s2.43,5.15,5.38,5.15,5.39-1.81,5.39-5.15S138.81,54.47,135.9,54.47Z"
        transform="translate(-0.34)"
        sx={{ fill: "primary" }}
      />
      <path
        d="M170.17,72.25c-8.24,0-13.73-3.69-14.15-8.62h10c.27,1.63,1.9,2.51,4.07,2.51,1.74,0,2.74-.71,2.74-1.59,0-3.61-15.73-.66-15.73-9.72,0-4.32,4.27-7.88,12.3-7.88s12.3,3.74,13,8.63h-9.35c-.31-1.55-1.63-2.47-3.85-2.47-1.69,0-2.59.57-2.59,1.5,0,3.52,15.79.7,15.84,10.07C182.47,69,177.82,72.25,170.17,72.25Z"
        transform="translate(-0.34)"
        sx={{ fill: "primary" }}
      />
      <circle cx="41.05" cy="123.84" r="14.06" sx={{ fill: "#c1252d" }} />
      <path
        d="M56.32,196.4c.23-1.66.4-3,.51-4.12s.23-2.46.33-4.14.2-3.54.28-5.58.15-4.4.2-7.09l5.12,0,5,0q-.44,3.87-.61,6.8t-.24,6.22q-.1,5.82-.1,7.91c-2,0-3.63,0-5,0S58.58,196.38,56.32,196.4Zm1.55-23.56c.1-1.63.15-2.91.15-3.86,0-.27,0-.64,0-1.1,2,0,3.65,0,5,0,1.85,0,3.48,0,4.87,0,0,.42-.08.78-.1,1.06l-.15,2.55c0,.38,0,.83,0,1.35-1.63,0-3.19,0-4.68,0S59.67,172.81,57.87,172.84Z"
        transform="translate(-0.34)"
        sx={{ fill: "text" }}
      />
      <path
        d="M72,196.4q.7-4.65,1.08-10.28c.25-3.75.4-7.31.45-10.65,2,0,3.67,0,5.1,0,1.66,0,3.26,0,4.78,0l-.24,2.78c.54-.5,1-.9,1.4-1.2a9.22,9.22,0,0,1,1.47-.86,10.19,10.19,0,0,1,1.85-.68,15.91,15.91,0,0,1,2.16-.42,17.49,17.49,0,0,1,2.45-.16,18.68,18.68,0,0,1,3.45.32,10.17,10.17,0,0,1,2.82.9,6.37,6.37,0,0,1,1.9,1.46,4.58,4.58,0,0,1,1,1.87,9,9,0,0,1,.24,2.08c0,.52-.07,1.64-.2,3.36l-.42,7.42c-.08,1.47-.13,2.82-.13,4.06-1.65,0-3.2,0-4.64,0-2,0-3.73,0-5.34,0q.23-1.86.47-5.54c.17-2.46.25-4.18.25-5.18a4.08,4.08,0,0,0-1.07-3.23,4,4,0,0,0-2.7-.91,5,5,0,0,0-4.65,3,15.64,15.64,0,0,0-1,4.89c-.19,2.27-.31,4.58-.38,6.93-1.64,0-3.24,0-4.8,0S73.86,196.38,72,196.4Z"
        transform="translate(-0.34)"
        sx={{ fill: "text" }}
      />
      <path
        d="M107.53,196.4q.47-3.93,1.13-15.05h-3c0-.42.12-1.33.21-2.71s.14-2.38.16-3h3.49c0-.55,0-1,0-1.42a15,15,0,0,1,.23-2.95,4.29,4.29,0,0,1,.86-1.81,5.57,5.57,0,0,1,1.78-1.34,8.2,8.2,0,0,1,2.58-.79,22.3,22.3,0,0,1,3-.2q1.43,0,3.09.15a34.58,34.58,0,0,1,4,.67c0,2.17.16,4,.32,5.41a15.27,15.27,0,0,0-4.38-.73,3.64,3.64,0,0,0-2.07.51,2.12,2.12,0,0,0-.75,1.88l0,.62h5.66c-.13,1.57-.27,3.47-.4,5.7h-5.11l-.81,15.05c-2,0-3.63,0-5,0S109.4,196.38,107.53,196.4Z"
        transform="translate(-0.34)"
        sx={{ fill: "text" }}
      />
      <path
        d="M141.05,174.93a21.94,21.94,0,0,1,5.87.78,14,14,0,0,1,4.76,2.25,9.19,9.19,0,0,1,2.87,3.42,9.86,9.86,0,0,1,.92,4.22,10.92,10.92,0,0,1-.82,4.17,10.26,10.26,0,0,1-2.8,3.71,12.68,12.68,0,0,1-5,2.6,24.31,24.31,0,0,1-12.44.1,13.81,13.81,0,0,1-4.81-2.22,9.44,9.44,0,0,1-2.9-3.48,9.77,9.77,0,0,1-1-4.27,10,10,0,0,1,.94-4.26,10.19,10.19,0,0,1,3-3.65,14.81,14.81,0,0,1,5.08-2.5A22.71,22.71,0,0,1,141.05,174.93Zm-.17,6a5.76,5.76,0,0,0-2.81.68,4.36,4.36,0,0,0-1.86,2,6.43,6.43,0,0,0-.63,2.84,4.38,4.38,0,0,0,1.36,3.45,4.91,4.91,0,0,0,3.39,1.2,5.67,5.67,0,0,0,2.78-.68,4.32,4.32,0,0,0,1.86-2,6.74,6.74,0,0,0,.63-2.89,5.2,5.2,0,0,0-.49-2.29,3.69,3.69,0,0,0-1.61-1.64A5.37,5.37,0,0,0,140.88,181Z"
        transform="translate(-0.34)"
        sx={{ fill: "text" }}
      />
    </svg>
  )
}
export default Logo
