import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react"
import { useThemeUI, useColorMode } from "theme-ui"

// PARAMS
const HEADER_BREAKPOINT_CHANGE = 4

const AppContext = createContext()
const useAppContext = () => {
  return useContext(AppContext)
}
const getWindowDimensions = breakpoints => {
  const { innerWidth: width, innerHeight: height } = window
  let breakpoint, idx
  for (idx in breakpoints) {
    breakpoint = parseInt(breakpoints[idx].replace(/px/, ""))
    if (breakpoint > width) {
      break
    }
  }
  return { idx, width, height }
}
const getHeaderSize = idx =>
  parseInt(idx) >= HEADER_BREAKPOINT_CHANGE ? "large" : "small"
const AppContextProvider = props => {
  const themeContext = useThemeUI()
  const breakpoints = themeContext.theme.breakpoints
  const lastYPos = useRef(0)
  const isScrollingUp = useRef(true)
  const shouldRevealToc = useRef(true)
  const lastBreakpoint = useRef(-1)
  const [colorMode, setColorMode] = useColorMode("default")
  // const [yPosition, setYPosition] = useState(0)
  const [showNav, setShowNav] = useState(true)
  const [showToc, setShowToc] = useState(false)
  // const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [size, setSize] = useState({
    breakpointIdx: null,
    headerSize: null,
    viewport: {
      width: null,
      height: null,
    },
  })

  // CLICK HANLDERS
  const colorModeClickHandler = mode => setColorMode(mode)

  useEffect(() => {
    const handleScroll = () => {
      const yPos = window.scrollY
      const newIsScrollingUp = (yPos < lastYPos.current) | (yPos < 150)
      if (isScrollingUp.current !== newIsScrollingUp) {
        isScrollingUp.current = newIsScrollingUp
        setShowNav(newIsScrollingUp)
      }

      if (shouldRevealToc.current !== yPos > 200) {
        shouldRevealToc.current = !shouldRevealToc.current
        setShowToc(shouldRevealToc.current)
      }
      lastYPos.current = yPos
    }
    const handleResize = () => {
      const { idx, width, height } = getWindowDimensions(breakpoints)
      if (idx !== lastBreakpoint.current) {
        lastBreakpoint.current = idx

        setSize({
          ...size,
          breakpointIdx: idx,
          viewport: { width, height },
          headerSize: getHeaderSize(idx),
        })
      }
    }
    handleResize()

    window.addEventListener("scroll", handleScroll, false)
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("scroll", handleScroll, false)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        showNav,
        setShowNav,
        showToc,
        size,
        setSize,
        colorMode,
        colorModeClickHandler,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

AppContextProvider.defaultProps = {}

export { AppContextProvider, useAppContext }
