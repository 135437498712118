import React from "react"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { useAppContext } from "../context/AppContext"
import { FiSun } from "react-icons/fi"
import { FaMoon } from "react-icons/fa"

const iconBtn = {
  width: "50px",
  height: "50px",
  margin: 3,
  cursor: "pointer",
}
const icon = {
  width: "100%",
  height: "100%",
}

const ThemeChange = props => {
  const { colorMode, colorModeClickHandler } = useAppContext()
  // console.log({ context })
  return (
    <Box
      sx={iconBtn}
      onClick={() =>
        colorModeClickHandler(colorMode === "default" ? "dark" : "default")
      }
    >
      {colorMode === "default" ? (
        <FaMoon sx={{ ...icon, color: "#0e0e1a" }} />
      ) : (
        <FiSun sx={{ ...icon, color: "primary" }} />
      )}
    </Box>
  )
}

ThemeChange.defaultProps = {}

export default ThemeChange
