import React from "react"
/** @jsx jsx */
import { jsx, Box, Flex, Text, Link } from "theme-ui"
// CUSTOM COMPONENTS
import SocialNav from "./Nav/SocialNav"
import ThemeChange from "./ThemeChange"

const menuWrapper = {
  height: "100%",
  backgroundColor: "background",
  paddingTop: "150px",
  paddingBottom: "100px",
  flexDirection: "column",
  justifyContent: "space-between",
}
const menuItemGroup = { alignSelf: "center" }
const menuItem = {
  paddingTop: 3,
  paddingBottom: 3,
}
const menuItemText = {
  fontSize: 5,
  color: "text",
}
const MobileMenu = props => {
  return (
    <Flex sx={menuWrapper}>
      <Box sx={menuItemGroup}>
        <Box sx={menuItem}>
          <ThemeChange />
        </Box>
      </Box>
      <Box sx={menuItemGroup}>
        <Box sx={menuItem}>
          <Link href="/">
            <Text sx={menuItemText}>Home</Text>
          </Link>
        </Box>
        <Box sx={menuItem}>
          <Link href="/About">
            <Text sx={menuItemText}>About</Text>
          </Link>
        </Box>
      </Box>
      <Box sx={menuItemGroup}>
        <Box sx={menuItem}>
          <SocialNav />
        </Box>
      </Box>
    </Flex>
  )
}

MobileMenu.defaultProps = {}

export default MobileMenu
